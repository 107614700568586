import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ConsentViewFilesComponent } from '../consent-view-files/consent-view-files.component';
import { ConsentRegistrationComponent } from '../consent-registration/consent-registration.component';
import { interval } from 'rxjs';
import { stringify } from 'querystring';

@Component({
  selector: 'app-consent-list',
  templateUrl: './consent-list.component.html',
  styleUrls: ['./consent-list.component.css']
})
export class ConsentListComponent implements OnInit {

  title:string='Consent List'
  pipe = new DatePipe('en-US');
  consentList: any=[];
  excelData:any;
  excelFileName = 'Consent List.xlsx';
  modalTitle: string = 'Add New Consent';
  benID: any;
  clearTrigger: any;
  lasUpdateOn: string;
  itemList: any=[];
  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService
    , private modalService: NgbModal
    , private excelDownloadService:ExcelDownloadService
    , private authSvc: AuthenticationService
    ) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());
  }

  ngOnInit(): void {
    // this.initFormControls(),
    this.LoadData();
    this.benID= sessionStorage.getItem('benIdConsent');
  // this.refreshCall();
    Common.loadDataTable(this.consentList, '#consentList');
    setInterval(res =>{
      this.lasUpdateOn = localStorage.getItem('lastUpdateOn');
   },10)
  }
  // refreshCall(){
  //   this.timeInterval= interval(2000*60).subscribe(x => {
  //     this.LoadData();
  //   });
  //   Common.loadDataTable(this.consentList, '#consentList');
  // }
  // clearRefreshCall(){
  //   clearTimeout(this.timeInterval);
  // }
  // Refresh data call 25-03-2023
  // @HostListener('mousemove', ['$event'])
  // onMousemove(event: MouseEvent) {
  //       if(event){
  //         clearInterval(this.clearTrigger)
  //       }
  //       this.clearTrigger =   setInterval(res =>{
  //         this.LoadData()
  //       },120000)
  // }

//  @HostListener('keydown', ['$event'])
//   onKeyDown(e) {
//     if (e.keyCode == 9 || e.keyCode==16) {
//         clearInterval(this.clearTrigger)
//     }
//  }
  
ngOnDestroy() {
  if (this.clearTrigger) {
    clearInterval(this.clearTrigger);
  }
}
  // end Refresh Data 25-03-2023
   LoadData() {
    let drId = JSON.parse(sessionStorage.getItem('DoctorDetails'))?.user_id ?? null;
    const lastUpdateOn: any = this.datePipe.transform(new Date(), 'MMM d, y, h:mm:ss a');
    localStorage.setItem('lastUpdateOn', lastUpdateOn)
      this.regService.getConsentsList(drId,sessionStorage.getItem('benIdConsent')).subscribe(res => {
      this.consentList = res.data;
      this.consentList.map(res=>{
         res.requested_hi_types= JSON.parse(res.requested_hi_types);
        //  res.status_history=JSON.stringify(res.status_history);
         res.status_history = (res?.status_history);

      })

      // console.log('New list',this.consentList);
        Common.loadDataTable(this.consentList, '#consentList');  
        this.excelData = this.consentList;
        Common.loadDataTable(this.consentList, '#consentList');
    });
  }
  ngAfterViewInit(){
    // console.log("after view init")
    this.clearTrigger =   setInterval(res =>{
              this.LoadData()
            },120000)
  }
  openViewModal(item:any) {
		// this.modalService.open(content, { scrollable: true , size: 'lg', centered: true });
    const modalRef = this.modalService.open(ConsentViewFilesComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = 'Consent Files';
    modalRef.componentInstance.consentId= item.consentid;
    modalRef.componentInstance.beneficiaryData= item;
    modalRef.result.then(result => {
      // if (result == 'changes saved')
        this.LoadData();
        // this.clearTrigger =   setInterval(res =>{
        //   this.LoadData()
        // },120000)
    }, (reason) => { });
    // const modalRef = this.modalService.open(ConsentFileDetailsComponent, { scrollable: true, size: 'xl' });
    // modalRef.componentInstance.title = 'Consent Files';
    // modalRef.componentInstance.consentId= item.consentid;
	}

  openPatientModal() {
      const modalRef = this.modalService.open(ConsentRegistrationComponent, { scrollable: true, size: 'lg' });
      modalRef.componentInstance.title = 'Consent Registration';
      modalRef.componentInstance.beId= this.benID;
      modalRef.result.then(result => {
        // if (result == 'changes saved')
          this.LoadData();
          // this.clearTrigger =   setInterval(res =>{
          //   this.LoadData()
          // },120000)
      }, (reason) => { });
    }


  // exportexcel
  // exportexcel(): void {
  //   var table = $('#networkMonthlyReport').DataTable();
  //   if (!table.data().count()) {
  //     this.toastr.error('No data available in table! Please select and try again');
  //   }
  //   else {
  //     / table id is passed over here /
  //     let element = document.getElementById('networkMonthlyReport');
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //     / generate workbook and add the worksheet /
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //     ws['!cols'] = [];
  //     ws['!cols'][10] = { hidden: true };
  //     / save to file /
  //     XLSX.writeFile(wb, this.excelFileName);
  //   }
  // }

  downLoadExcel(){
    const excelFileName = 'Consent list'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.consentCreatedOn = this.pipe.transform(res.consent_created_on, 'dd-MM-yyyy HH:mm:ss');
          res.consentdataEraseAt = this.pipe.transform(res.data_erase_at, 'dd-MM-yyyy HH:mm:ss');
          res.consentdataFrom = this.pipe.transform(res.data_from, 'dd-MM-yyyy HH:mm:ss');
          res.consentdataTo = this.pipe.transform(res.data_to, 'dd-MM-yyyy HH:mm:ss');
        })
        // created_on
        const excelHeaders = ['Patient Name', 'Consent Id','Consent Created On','Data Erase At','Data From','Data To', 'ABHA Address', 'Request Status'];
        const internalHeaders = ['patient_full_name', 'consentid','consentCreatedOn','data_erase_at','consentdataFrom','consentdataTo' ,'abha_address', 'request_status'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,excelFileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
  }
}
