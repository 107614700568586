<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="card">
            <!-- <div class="row">
                <div class="col-xl-6">
                    <div class="card-header">
                        <div class="col-xl-4"><a>Appointments</a></div>
                        <div class="col-xl-7"></div>
                        <div class="col-xl-1">
                            <a data-toggle="modal" data-target="#appointmnt"><i class="fas fa-external-link-alt"></i></a>
                        </div>
                    </div>
                    <div class="card-body p-2">
                        <table class="table table-borderless table-sm">
                            <tbody *ngIf="apntmntLstGrid.length!=0">
                                <tr *ngFor="let g of apntmntLstDtls">
                                    <td>{{g.appointment_id}}</td>
                                    <td>{{g.appointment_date | date: 'dd-MM-yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-header">
                        <div class="float-start"><a>Op Billing</a></div>
                        <div class="float-end">
                            <a class="pntr" data-toggle="modal" data-target="#opBiling"><i class="fas fa-external-link-alt"></i></a>
                        </div>
                    </div>
                    <div class="card-body p-2">
                       
                                <table class="table table-borderless table-sm">
                                    <tbody *ngIf="opLstGrid.length!=0">
                                        <tr *ngFor="let g of opLstDtls">
                                            <td>{{g.consultation_bill_no}}</td>
                                            <td>{{g.consultation_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                           
                    </div>
                    <div class="card-header">
                        <div class="col-xl-3"><a>Laboratory</a></div>
                        <div class="col-xl-8"></div>
                        <div class="col-xl-1">
                            <a class="pntr" data-toggle="modal" data-target="#laboratory"><i class="fas fa-external-link-alt"></i></a>
                        </div>       
                </div>
                <div class="card-body">
                    <table class="table table-borderless table-sm">
                        <tbody>
                            <tr>
                                <td>{{labLstDtls.order_no}}</td>
                                <td>{{labLstDtls.order_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card-header">
                    <div class="col-xl-3"><a>Radiology</a></div>
                    <div class="col-xl-8"></div>
                    <div class="col-xl-1">
                        <a class="pntr" data-toggle="modal" data-target="#radiology"><i class="fas fa-external-link-alt"></i></a>
                    </div>
            </div>
            <div class="card-body">
                <table class="table table-borderless table-sm">
                    <tbody>
                        <tr>
                            <td>{{rdlgyLstDtls.order_no}}</td>
                            <td>{{rdlgyLstDtls.order_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div> -->

            <div class="row">
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Appointments</a></div>
                            <!-- <div class="col-xl-7"></div> -->
                            <div class="col-xl-1">
                                <a data-toggle="modal" data-target="#appointmnt"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <table class="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Op Billing</a></div>
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#opBiling"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">     
                            <table class="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Doctor Consultations</a></div>
                            <!-- <div class="col-xl-6"></div> -->
                            <div class="col-xl-1">
                                <a data-toggle="modal" class="pntr" data-target="#dcCnsultation"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <table class="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <td>{{consultListDtls?.mrn_no}}</td>
                                        <td>{{consultListDtls?.bill_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>IP Details</a></div>
                            <!-- <div class="col-xl-7"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#ipDtls"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <table class="table table-borderless table-sm">
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Laboratory</a></div>
                            <!-- <div class="col-xl-8"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#laboratory"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>{{labLstDtls?.order_no}}</td>
                                            <td>{{labLstDtls?.order_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Radiology</a></div>
                            <!-- <div class="col-xl-8"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#radiology"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>{{rdlgyLstDtls?.order_no}}</td>
                                            <td>{{rdlgyLstDtls?.order_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Diagnosis</a></div>
                            <!-- <div class="col-xl-7"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#diagnosis"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <!-- <div class="col-7"><span class="text-primary">07 June 17</span> <span class="text-warning"><i class="fas fa-star"></i></span></div>
                            <div class="col-5">OPD</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Vitals</a></div>
                            <!-- <div class="col-xl-7"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#vitals"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <!-- <div class="col-7"><span class="text-primary">07 June 17</span> <span class="text-warning"><i class="fas fa-star"></i></span></div>
                            <div class="col-5">OPD</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Procedures</a></div>
                            <!-- <div class="col-xl-7"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#procedures"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <!-- <div class="col-7"><span class="text-primary">07 June 17</span> <span class="text-warning"><i class="fas fa-star"></i></span></div>
                            <div class="col-5">OPD</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card shadow-sm mb-2">
                        <div class="card-header bg_card_color">
                            <div class="col-xl-11"><a>Others Details</a></div>
                            <!-- <div class="col-xl-7"></div> -->
                            <div class="col-xl-1">
                                <a class="pntr" data-toggle="modal" data-target="#otherDtls"><i
                                        class="fas fa-external-link-alt"></i></a>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <!-- <div class="col-7"><span class="text-primary">07 June 17</span> <span class="text-warning"><i class="fas fa-star"></i></span></div>
                            <div class="col-5">OPD</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="activeModal.close('Close click')">Close</button>
    </div>

    <!-- Appointments Start -->
    <div class="modal fade" id="appointmnt" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="appointmntLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="appointmntLabel">Appointmnts</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div [ngClass]="apntmntDtls == '' ? 'col-md-12':'col-md-6'">
                            <div class="table-responsive mt-1">
                                <table class="table table-bordered table-striped table-sm mb-1">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Appointment Number</th>
                                            <th>MRN</th>
                                            <th>Patient Name</th>
                                            <th>Admission Date</th>
                                            <!-- <th>Mobile Number</th> -->
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="apntmntLstGrid.length!=0">
                                        <tr class="pntr" *ngFor="let g of apntmntLstGrid; let i=index"
                                            (click)="LoadApntmntView(g.appointment_id)">
                                            <td class="text-center">{{i+1}}</td>
                                            <td>{{g.appointment_id}}</td>
                                            <td>{{g.mrn_no}}</td>
                                            <td>{{g.patient_first_name}}</td>
                                            <td>{{g.appointment_date | date: 'dd-MM-yyyy'}}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal"
                        (click)="apntmntDtls=''">Close</button>

                </div>
            </div>
        </div>
    </div>
    <!-- Appointments End -->

    <!-- op Billing Start -->
    <!-- <div class="modal fade" id="opBiling" data-backdrop="static" data-keyboard="false" tabindex="-1"
 aria-labelledby="opBilingLabel" aria-hidden="true">
 <div class="modal-dialog modal-dialog-centered modal-lg">
     <div class="modal-content">
         <div class="modal-header">
             <h5 class="modal-title" id="opBilingLabel">OP Billing</h5>
             <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
             <div class="row">
                     <div class="table-responsive mt-1">
                         <table class="table table-bordered table-striped table-sm mb-1">
                             <thead>
                                 <tr>				
                                     <th>S.No</th>
                                     <th>Bill Number</th>
                                     <th>MRN</th>
                                     <th>Patient Name</th>
                                     <th>Bill Date</th>
                                 </tr>
                             </thead>
                             <tbody *ngIf="opLstGrid.length!=0">
                                 <tr class="pntr" *ngFor="let g of opLstGrid; let i=index" (click)="LoadView(g.bill_id)">
                                     <td class="text-center">{{i+1}}</td>
                                     <td>{{g.consultation_bill_no}}</td>
                                     <td>{{g.mrno}}</td>
                                     <td>{{g.patient_name}}</td>
                                     <td>{{g.consultation_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                 </tr>
                             </tbody>
                         </table>
                     </div>
             </div>
         </div>
         <div class="modal-footer">
             <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal" (click)="opBlngDtls='';billingDtals=''">Close</button>
         </div>
     </div>
 </div>
</div> -->
    <!-- op Billing End -->


    <!-- DC Consultation Start -->
    <div class="modal fade" id="dcCnsultation" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="dcCnsultationLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="dcCnsultationLabel">Doctor Consultations </h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="table-responsive mt-1">
                            <table class="table table-bordered table-striped table-sm mb-1">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Consultation Bill Number</th>
                                        <th>MRN</th>
                                        <th>Patient Name</th>
                                        <th>Consultation Bill Date</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="pntr" *ngFor="let g of doctorConsultList; let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td>{{g?.bill_id}}</td>
                                        <td>{{g?.mrn_no}}</td>
                                        <td>{{g?.patientname}}</td>
                                        <td>{{g?.bill_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                        <td><button type="button" class="btn btn-sm btn-primary"
                                            (click)="openDctrCnsltReport(g)"> view
                                        </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal"
                        (click)="dcBlngDtls=''">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- DC Consultation End -->


    <!-- IP Details -->
    <div class="modal fade" id="ipDtls" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="ipDtlsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ipDtlsLabel">IP Details</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="table-responsive mt-1">
                            <table class="table table-bordered table-striped table-sm mb-1">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>IP Number</th>
                                        <th>MRN</th>
                                        <th>Patient Name</th>
                                        <th>Admission Date</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="ipLstGrid.length!=0">
                                    <tr class="pntr" *ngFor="let g of ipLstGrid; let i=index"
                                        (click)="LoadIPView(g.ipid)">
                                        <td class="text-center">{{i+1}}</td>
                                        <td>{{g.ipno}}</td>
                                        <td>{{g.mrn_no}}</td>
                                        <td>{{g.patientname}}</td>
                                        <td>{{g.admission_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal"
                        (click)="ipBlngDtls=''">Close</button>

                </div>
            </div>
        </div>
    </div>
    <!-- IP Details End -->

    <!-- Laboratory Start -->
    <div class="modal fade" id="laboratory" data-backdrop="static" data-keyboard="false"
        aria-labelledby="laboratoryLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="laboratoryLabel">Laboratory</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="table-responsive mt-1">
                            <table class="table table-bordered table-striped table-sm mb-1">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Order Number</th>
                                        <th>MRN</th>
                                        <th>Patient Name</th>
                                        <th>service Name</th>
                                        <th>Order Date</th>
                                        <th>view</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let g of labLstGrid; let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td>{{g?.order_no}}</td>
                                        <td>{{g?.mrn_no}}</td>
                                        <td>{{g?.patient_name}}</td>
                                        <td>{{g?.service_name}}</td>
                                        <td>{{g?.order_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                        <td><button type="button" class="btn btn-sm btn-primary"
                                                (click)="openLabReport(g)"> view
                                            </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal"
                        (click)="labDtls=''">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Laboratory end -->


    <!-- Radiology Start -->
    <div class="modal fade" id="radiology" data-backdrop="static" data-keyboard="false" aria-labelledby="radiologyLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="radiologyLabel">Radiology</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="table-responsive mt-1">
                            <table class="table table-bordered table-striped table-sm mb-1">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Order Number</th>
                                        <th>MRN</th>
                                        <th>Patient Name</th>
                                        <th>Order Date</th>
                                        <th>view</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let g of radologyLstGrid; let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td>{{g.order_no}}</td>
                                        <td>{{g.mrn_no}}</td>
                                        <td>{{g.patient_name}}</td>
                                        <td>{{g.order_date | date: 'dd-MM-yyyy hh:MM:ss'}}</td>
                                        <td> <button type="button" class="btn btn-sm btn-primary"
                                                (click)="openRadReport(g)">view
                                            </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal"
                        (click)="radologyDtls=''">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Radiology End -->

    <!-- Diagnosis Start -->
    <div class="modal fade" id="diagnosis" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="diagnosisLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="diagnosisLabel">Diagnosis</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    diagnosis
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
    <!-- Diagnosis End -->


    <!-- Vitals Start -->
    <div class="modal fade" id="vitals" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="vitalsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="vitalsLabel">Vitals</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    vitals
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
    <!-- Vitals End -->


    <!-- Procedures Start -->
    <div class="modal fade" id="procedures" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="proceduresLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="proceduresLabel">Procedures</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    procedures
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
    <!-- Procedures End -->


    <!-- Other Details Start -->
    <div class="modal fade" id="otherDtls" data-backdrop="static" data-keyboard="false" tabindex="-1"
        aria-labelledby="otherDtlsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="otherDtlsLabel">Other Details</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    otherDtls
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
    <!-- Other Details End -->