<!-- View modal start -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Modal title</h4>
      <button type="button" class="btn btn-outline-dark btn-close" aria-label="Close"
        (click)="modal.dismiss('Cross click')">X</button>
    </div>
    <div class="modal-body">
      <p>One fine body&hellip;</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>
  <!-- View modal end -->
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header d-flex justify-content-between align-items-center">
            {{title}}
            <div>
              <button class="btn btn-info btn-sm me-3" (click)="downLoadExcel()">Export Excel</button>&nbsp;
              <input type="submit" class="btn btn-sm btn-primary" value="Refresh"
              (click)="LoadData()">&nbsp;
             
              <button data-toggle="modal" (click)="modalTitle='Patient Registration Details'; openPatientModal()"
                class="btn btn-blue btn-icon float-right" style="margin-top: -5px !important;"> <i class="fa fa-plus"></i>
              </button>
             
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table datatable id="consentList" class="table table-striped table-bordered table-sm text-center"
                style="width:100%">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>ABHA Address</th>
                    <th>Patient Name</th>
                    <th>Request Status</th>
                    <!-- <th>Consent Id</th> -->
                    <th>Requested For</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Consent Created On</th>
                    <th>Consent Expiry On</th>
                    <th>Status History</th>
                    <!-- <th>Consent Created On</th> -->
                    <!-- <th>Data Erase At</th> -->
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of consentList; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{item?.abha_address}}</td>
                    <td>{{item?.patient_full_name | uppercase}}</td>
                    <td *ngIf="item?.request_status?.includes('REQUESTED')" style="color: orange;">{{item?.request_status}}</td>
                    <td *ngIf="item?.request_status?.includes('DENIED')" style="color: red;">{{item?.request_status}}</td>
                    <td *ngIf="item?.request_status?.includes('GRANTED')" style="color: green;">{{item?.request_status}}</td>
                    <td *ngIf="item?.request_status?.includes('EXPIRED')" style="color: red;">{{item?.request_status}}</td>
                    <td *ngIf="item?.request_status?.includes('REVOKE')" style="color: blue;">{{item?.request_status}}</td>
                
                    <td>
                      <span *ngFor="let ob of item?.requested_hi_types;let i=index">
                        {{ob}}<br/>
                      </span>
                    </td>
                    <!-- <td>{{item?.consentid}}</td> -->
                    <td>{{item?.data_from | date: 'dd-MM-yyyy'}}</td>
                    <td>{{item?.data_to | date: 'dd-MM-yyyy'}}</td>
                    <!-- <td>{{item?.consent_created_on | date: 'dd-MM-yyyy & HH:mm'}}</td> -->
                    <td>{{item?.consent_created_on | date:'dd-MM-yyyy h:mm:ss a'}}</td>
                    <td>{{item?.data_erase_at | date:'dd-MM-yyyy h:mm:ss a'}}</td>
                   <td>
                    <!-- <span *ngFor="let ob of ;let i=index">
                      {{ob}}<br/>
                    </span> -->
                    {{item?.status_history}}
                </td>
                  
                   
                   
                    
                    <!-- <td>
                      <span *ngIf="item?.consent_status == 'GRANTED'"
                        style="color:green; font-weight: bold;">{{item?.consent_status}} </span>
                      <span *ngIf="item?.consent_status == 'EXPIRED'"
                        style="color:red; font-weight: bold;">{{item?.consent_status}} </span>
                      <span *ngIf="item?.consent_status == 'REVOKED'"
                        style="color:orange; font-weight: bold;">{{item?.consent_status}} </span>
                      <span *ngIf="item?.consent_status == 'DENIED'"
                        style="color: rgb(134, 73, 73); font-weight: bold;">{{item?.consent_status}} </span>
                    </td> -->
                    <!-- <td>{{item?.created_on | date:'dd-MM-yyyy & HH:mm'}}</td>
                    <td>
                      <span *ngIf="item?.is_deleted==true">Yes</span>
                      <span *ngIf="item?.is_deleted==false">No</span>
                    </td> -->
                    <td>
                      <!-- <button class="btn btn-sm btn-primary">View</button> -->
                      <button class="btn btn-primary mb-2 me-2 btn-sm"  *ngIf="item?.request_status?.includes('GRANTED')" (click)="openViewModal(item)">View Records</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>