import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';
declare let $: any;
@Component({
  selector: 'app-consultation-link-with-abha',
  templateUrl: './consultation-link-with-abha.component.html',
  styleUrls: ['./consultation-link-with-abha.component.css']
})
export class ConsultationLinkWithAbhaComponent implements OnInit {
  @Input() data: any;
  @Input() title: string = '';
  abhaNumber:any='';
  respData: any;
  modesData: any=[];
  verifyMthd: string;
  isDemoGraphic: boolean;
  isDemoGraphicM: boolean;
  showfrgtBtn: boolean;
  isForgotMob: boolean;
  selectedMode:any = '';
  respGetCareContextData: any;
  verifyotp:any=null;
  name:any='';
  demographicMobile:any;
  gender:any;
  dob:any;
  max_date = new Date(new Date().setFullYear(+`${new Date().getFullYear() - 21}`));
  demographicForm: FormGroup;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = '';
  submitted = false;
  public showPassword: boolean;
  public showPasswordOnPress: boolean;
  genderList:any=[{'gender_id':'M' ,'gender_name':'Male'}
,{'gender_id':'F' ,'gender_name':'Female'}
,{'gender_id':'O' ,'gender_name':'Transgender'}];
  isAdhaarOtp: boolean;
  directAuthCallback: boolean;
  isPassword: boolean;
  verifyPswd: any=null;
  dgName:any='';
  dgGender:any='';
  dgDob:any='';
  dgMobile:any='';
  demographyData: any=null;
  isCallbackRespAvail: boolean;
  modesRetry: boolean;
  hitMobileOtp: boolean;
  legacy:any=null;
  service:any=null;
  doc: any;
  doctorHospital :any=[];
  hfrCode: any;
  transactionId: any;
  isDisable: boolean;
  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe,private formBuilder: FormBuilder
    , private modalService: NgbModal,private authServ: AuthenticationService
    , private toastr: ToastrService, private http: HttpClient
    , private sanitizer: DomSanitizer, private registrationService:RegistrationService) { }

  ngOnInit() {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), 
    this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
    this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.initFormControls();
    this.doc = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    this.doctorHospital=this.doc.doctor_hospitals;
    this.hfrCode=this.doctorHospital[0].hfr_code;
    console.log(this.hfrCode)
    if(this.data.health_id!==null){
      this.abhaNumber=this.data.health_id;
      this.existhealthID();
    
    }
  }
  onChk(check:any,text:any){
    if(check){
      if(text==='legacy'){
        this.legacy=true;
      }else if(text==='service'){
       this.service=true;
      }
    } else{
      if(text==='legacy'){
        this.legacy=false;
      }else if(text==='service'){
      this.service=false;
      }
    }
 
  }
  initFormControls() {
    this.demographicForm = this.formBuilder.group({
      name: ['', Validators.required],
      birth_date: ['', Validators.required],
      gender_id: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
     
    });
  }
  get f() { return this.demographicForm.controls; }
  existhealthID(){
    const uuId = uuid.v4();
    let timeStamp = new Date();
 
   let payLoad={'requestId':uuId,'timestamp':timeStamp.toISOString(),'query':{
    'id':this.abhaNumber,
    'purpose':'LINK',
    'requester':{
      'type':'HIP',
      'id':this.hfrCode
    }
   }}
this.registrationService.verifyAbha(payLoad).subscribe(res => {

  if(res.status==='OK'){
  this.toastr.success(res.message);
    this.respData = res.data;
    // this.getModes();
    setTimeout(() => {
     
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.getModes();
    }, 5000);
  } else{
    this.toastr.error(res.message);
  }

  
})

  }
  getModes(){
    this.modesRetry=false;
   this.registrationService.fetchNodes(this.respData.request_id).subscribe(res => {
      if(res.status==='OK'){
        this.toastr.success(res.message);
        if(res.data.response && res.data.response!==null){
          this.modesData = res.data.response.auth.modes;
          this.modesRetry=true;
          this.isCallbackRespAvail=true;
        }else{
          this.toastr.error(res.message);
          this.isCallbackRespAvail=false;
        }
        
        // this.initCallback();
        // this.modesData.push('DIRECT');
      } else {
        this.toastr.error(res.message);
      }
    
      
   })
  }
  onMethodChange(e: any) {
    this.modesRetry=false;
    this.selectedMode=e;
    if (e == 'AADHAAR_OTP') {
      this.toastr.success('OTP send to '+' AADHAAR Linked Mobile');
      this.verifyMthd = 'Aadhaar Number'
      this.directAuthCallback=false;
      this.isDemoGraphic = false;
      this.isDemoGraphicM = false;
      this.showfrgtBtn = false;
      this.isForgotMob = false;
      this.isAdhaarOtp= false;
      this.isPassword = false;
      setTimeout(() => {
     
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping }); id.substr(id.length - 5)
        this.onSelectMode(e);
    }, 1000);
    }
    else if (e == 'MOBILE_OTP') {
       this.hitMobileOtp=false;
      this.toastr.success('OTP send to '+'******'+(this.data.beneficiary_mobile.substr(10-4)));
      this.verifyMthd = 'Mobile Number'
      this.isAdhaarOtp= false;
      this.directAuthCallback= false;
      this.isDemoGraphic = false;
      this.isDemoGraphicM = false;
      this.showfrgtBtn = false;
      this.isForgotMob = false;
      this.isPassword = false;
      setTimeout(() => {
     
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.onSelectMode(e);
    }, 1000);
    }
   else if (e == 'DEMOGRAPHICS') {
      this.isAdhaarOtp= false
      this.directAuthCallback=false;
      this.isDemoGraphic = true;
      this.showfrgtBtn = false;
      this.isForgotMob = false;
       this.isDemoGraphicM = true;
       this.isPassword = false;
       setTimeout(() => {
     
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.demographyDtlsFetch(e);
      
    }, 1000);
       
      //  this.onSelectDemographics();
    } 
    else if (e == 'PASSWORD') {
      this.verifyMthd = 'Password';
      this.isAdhaarOtp= false;
      this.directAuthCallback= false;
      this.isDemoGraphic = false;
      this.isDemoGraphicM = false;
      this.showfrgtBtn = false;
      this.isForgotMob = false;
      this.isPassword = false;
      setTimeout(() => {
     
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.onSelectMode(e);
    }, 1000);
    }
    else {
      this.verifyMthd = 'Direct';
      this.directAuthCallback= true;
      this.isDemoGraphic = false;
      this.isDemoGraphicM = false;
      this.showfrgtBtn = false;
      this.isForgotMob = false;
      this.isAdhaarOtp= false;
      this.isPassword = false;
      setTimeout(() => {
     
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.onDirectSelectMode(e);
    }, 5000);
    }
  }
  // 29-12-2022 new line added for the demograpy fetch
  demographyDtlsFetch(e){
    //this.data.beneficiary_id 322
    this.onSelectMode(e);
    this.registrationService.getDemograpyDtls(this.data.beneficiary_id).subscribe(res => {
      this.demographyData = res.data;
    if(res.status==='OK'){
      if(res.data.name && res.data.name!=null && res.data.gender && res.data.gender!=null && res.data.dateOfBirth && res.data.dateOfBirth!=null){
        this.dgName=res.data.name;
        this.dgGender=res.data.gender;
        if(this.dgGender!= null){
          this.isDisable=true
        }

        this.dgDob= new Date(res.data.dateOfBirth);
        this.dgMobile=res.data.identifier.value;
      } else{
        this.toastr.info(res.message);
        return;
      }
      
    
    }else {
      this.toastr.error(res.message);
    }
  
    })
  }
  onSelectMode(e){

    const uuId = uuid.v4();
    let timeStamp = new Date();
    // if(){

    // }
 
   let payLoad={'requestId':uuId,'timestamp':timeStamp.toISOString(),'query':{
    'id':this.abhaNumber,
    'purpose':'LINK',
    'authMode':this.selectedMode,
    'requester':{
      'type':'HIP',
      'id':this.hfrCode
    }
   }}

   this.registrationService.initMode(payLoad).subscribe(res => {

if(res.status==='OK'){
this.toastr.success(res.message);
  this.respData = res.data;
 
  setTimeout(() => {
     
    // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
    this.initCallback();
}, 5000);
} else{
  this.toastr.error(res.message);
}


})

  }
  onDirectSelectMode(e){

    const uuId = uuid.v4();
    let timeStamp = new Date();
    // if(){

    // }
 
   let payLoad={'requestId':uuId,'timestamp':timeStamp.toISOString(),'query':{
    'id':this.abhaNumber,
    'purpose':'KYC_AND_LINK',
    'authMode':'DIRECT',
    'requester':{
      'type':'HIP',
      'id':this.hfrCode
    }
   }}

   this.registrationService.initMode(payLoad).subscribe(res => {

if(res.status==='OK'){
this.toastr.success(res.message);
  this.respData = res.data;
 
  setTimeout(() => {
     
    // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
    this.initCallback();
}, 5000);
} else{
  this.toastr.error(res.message);
}


})

  }
  onSelectDemographics(){
    // this.isDemoGraphic= !this.isDemoGraphic;
    
  }
  //Init Callback method 19-10-2022
  initCallback(){
    if(this.directAuthCallback){
      this.registrationService.directCallbackInit(this.abhaNumber).subscribe(res => {
     
        if(res.status==='OK'){
          if(res.data.response && res.data.response!=null && res.data.response.error==null){
            this.isCallbackRespAvail=true;
            this.toastr.success(res.message);
            //this.modesData = res.data.response.auth.modes;
            this.respData= res.data;
            this.transactionId=res.data?.transactionId;

          }else{
            this.toastr.error(res.data.response.error.message);
            this.isCallbackRespAvail=false;
            return;
          }
         
         
        } else {
          this.toastr.error(res.message);
        }
      
        
     })

    }else if(this.selectedMode==='MOBILE_OTP'){
      this.registrationService.callbackInit(this.respData.request_id).subscribe(res => {
        if(res.status==='OK'){
          if(res.data.response && res.data.response!=null && res.data.response.error==null){
            this.toastr.success(res.message);
            //this.modesData = res.data.response.auth.modes;
           
               this.isForgotMob=true;
              
            this.isCallbackRespAvail=true;
            this.respData= res.data;
            this.transactionId=res.data?.transactionId;

          }else{
            this.toastr.error(res.data.response.error.message);
            this.isCallbackRespAvail=false;
            return;
          }
         
         
        } else {
          this.toastr.error(res.message);
        }
      
        
     })
    } else if(this.selectedMode==='AADHAAR_OTP'){
      this.registrationService.callbackInit(this.respData.request_id).subscribe(res => {
        if(res.status==='OK'){
          if(res.data.response && res.data.response!=null && res.data.response.error==null){
            this.toastr.success(res.message);
            //this.modesData = res.data.response.auth.modes;
           
               this.isAdhaarOtp=true;
              
            this.isCallbackRespAvail=true;
            this.respData= res.data;
            this.transactionId=res.data?.transactionId;

          }else{
            this.toastr.error(res.data.response.error.message);
            this.isCallbackRespAvail=false;
            return;
          }
         
         
        } else {
          this.toastr.error(res.message);
        }
      
        
     })
    } 
    else if(this.selectedMode==='PASSWORD'){
      this.registrationService.callbackInit(this.respData.request_id).subscribe(res => {
        if(res.status==='OK'){
          if(res.data.response && res.data.response!=null && res.data.response.error==null){
            this.toastr.success(res.message);
            //this.modesData = res.data.response.auth.modes;
           
               this.isPassword=true;
              
            this.isCallbackRespAvail=true;
            this.respData= res.data;
            this.transactionId=res.data?.transactionId;

          }else{
            this.toastr.error(res.data.response.error.message);
            this.isCallbackRespAvail=false;
            return;
          }
         
         
        } else {
          this.toastr.error(res.message);
        }
      
        
     })
    } 
    
  
    else{
      this.registrationService.callbackInit(this.respData.request_id).subscribe(res => {
        if(res.status==='OK'){
          if(res.data.response && res.data.response!=null && res.data.response.error==null){
            this.toastr.success(res.message);
            //this.modesData = res.data.response.auth.modes;
           
               this.hitMobileOtp=true;
            
            this.isCallbackRespAvail=true;
            this.respData= res.data;
            this.transactionId=res.data?.transactionId;

          }else{
            this.toastr.error(res.data.response.error.message);
            this.isCallbackRespAvail=false;
            return;
          }
         
         
        } else {
          this.toastr.error(res.message);
        }
      
        
     })
    }
   
  }
  //confirm callback 19-10-2022
  confirmCallback(){
    
      this.registrationService.confirmCallback(this.respData.request_id).subscribe(res => {
       if(res.status==='OK'){
        if(res.data.response && res.data.response!=null && res.data.response.error==null){
          this.isCallbackRespAvail=true;
          this.toastr.success(res.message);
          //this.modesData = res.data.response.auth.modes;
          this.respData=res.data;
          // setTimeout(() => {
      
           // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
           this.getLatestCareContext();
      //  }, 1000);

        } else{
          this.toastr.error(res.data.response?.error?.message);
          this.isCallbackRespAvail=false;
          return;
        }
        
       } else {
         this.toastr.error(res.message);
       }
     
       
    })
   
  }
  verifyOtp(){
  //API INIT CONFIRM s  
  
  const uuId = uuid.v4();
  let timeStamp = new Date();

//  let payLoad={'requestId':uuId,'timestamp':timeStamp.toISOString(),'query':{
//   'id':this.abhaNumber,
//   'transactionId':this.respData?.response?.auth['transactionId'],
//   'credential':{
//     'authCode':'278052'
//   }
//  }}
// let jsonData=JSON.stringify(this.respData)
if(this.verifyotp!==null && this.verifyotp.length==6){
  let payLoad={'requestId':uuId,'timestamp':timeStamp.toISOString(),
  'transactionId':this.respData.response.auth.transactionId,
  'credential':{
    'authCode':this.verifyotp
  }
 }
 this.registrationService.authConfirm(payLoad).subscribe(res => {


if(res.status==='OK'){
  // if(res.data.response && res.data.response!=null && res.data.response.error==null){
    this.toastr.success(res.message);
    this.respData = res.data;
    // this.initCallback();
    setTimeout(() => {
         
      // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
      this.confirmCallback();
    }, 5000);
  // } else{
  //   this.toastr.error(res.data.response.error.message);
  //   return;
  // }

} else{
this.toastr.error(res.message);
}


})

} else{
  this.toastr.error("Please enter OTP");
}


  }
  //Verify password 16-12-2022
  verifyPassword(){
    //API INIT CONFIRM s  
    
    const uuId = uuid.v4();
    let timeStamp = new Date();
    if(this.verifyPswd!=null && this.verifyPswd!==''){
      let payLoad={'requestId':uuId,'timestamp':timeStamp.toISOString(),
      'transactionId':this.respData.response.auth.transactionId,
      'credential':{
        'authCode':this.verifyPswd
      }
     }
     this.registrationService.authConfirm(payLoad).subscribe(res => {
   
    
    if(res.status==='OK'){
      if(res.data.response && res.data.response!=null && res.data.response.error==null){
        this.toastr.success(res.message);
    this.respData = res.data;
    // this.initCallback();
    setTimeout(() => {
         
      // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
      this.confirmCallback();
    }, 5000);
      } else{
        this.toastr.error(res.data.response.error.message);
      return;
      }
    
    } else{
    this.toastr.error(res.message);
    }
    
    
    })
    }else{
    this.toastr.error("Please enter Password");
    }
 
  
    }
  //Benificiary more info latest care context API Call 20-10-2022
  getLatestCareContext(){
    this.registrationService.getLatestCareContext(this.data.beneficiary_id,this.data.patient_interaction_id).subscribe(res => {
    
     if(res.status==='OK'){
      // if(res.data.response && res.data.response!=null && res.data.response.error==null){
        this.toastr.success(res.message);
        //this.modesData = res.data.response.auth.modes;
        this.respGetCareContextData= res.data;
        setTimeout(() => {
      
         // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
         this.addCareContext();
       }, 5000);
      // } else{
      //   this.toastr.error(res.data.response.error.message);
      //   return;
      // }
      
     } else {
       this.toastr.error(res.message);
     }
   
     
  })
 
}

//Add care context 01-12-2022
addCareContext(){
  const uuId = uuid.v4();
  let timeStamp = new Date();
  let payload={
    'requestId':uuId,
    'timestamp':timeStamp.toISOString(),
    'link':{
      'accessToken':this.respData.response?.auth['accessToken'],
      'patient':{
        'referenceNumber':this.respGetCareContextData[0]?.patient_referencenumber,
        'display':this.respGetCareContextData[0]?.patient_display,
        'careContexts':[{
          'referenceNumber':this.respGetCareContextData[0]?.carecontexts_referencenumber,
          'display':this.respGetCareContextData[0]?.carecontexts_display
        }]
    
      }
    }
  }
  this.registrationService.addCareContext(payload).subscribe(res => {
 
   if(res.status==='OK'){
    // if(res.data.response && res.data.response!=null && res.data.response.error==null){
      this.toastr.success(res.message);
     //this.modesData = res.data.response.auth.modes;
     this.respData = res.data;
     setTimeout(() => {
     
      // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
      this.careContextCallback();
    }, 5000);
   
    // } else{
    //   this.toastr.error(res.data.response.error.message);
    //   return;
    // }
     
     
   } else {
     this.toastr.error(res.message);
   }
 
   
})

}

// add care context callback 06-12-2022
careContextCallback(){
  
  this.registrationService.careContextCallback(this.respData.request_id).subscribe(res => {
  
  
   if(res.status==='OK'){
    if(res.data.response && res.data.response!=null && res.data.response.error==null){
      this.toastr.success(res.message);
      // this.respData=res.data;
      this.isCallbackRespAvail=true;
    
      this.closeModal();

    } else{
      this.toastr.error(res.data.response.error.message);
      this.isCallbackRespAvail=false;
     
      return;
    }
    
   } else {
     this.toastr.error(res.message);
   }
 
   
})

}
closeModal() {
  this.activeModal.close('changes saved');
}
onKeyNmbr(type: string, val: string) {
  if (val != '') {
    if (+this.maxLngth == +environment.maxLngthSAGE) {
      if (+val[0] == environment.mobValdValSAGE)
        this.demographicForm.get(type).setValue('');
    }
    if (+this.maxLngth == +environment.maxLngthIND) {
      if (+val[0] < environment.mobValdValIND)
        this.demographicForm.get(type).setValue('');
    }
    if (+this.maxLngth == +environment.maxLngthNIG) {
      if (+val[0] > environment.mobValdValNIG)
        this.demographicForm.get(type).setValue('');
    }
  }
}
//Submit demographic 05-12-2022
demographySubmit(){
  const uuId = uuid.v4();
    let timeStamp = new Date();
   
    if(this.dgName!=='' && this.dgGender!=='' && this.dgDob!=='' && this.dgDob!=undefined && this.dgMobile!==''){
      let payLoad= {
        "requestId": uuId,
        "timestamp": timeStamp.toISOString(),
        // "transactionId":this.respData.response?.auth?.transactionId,
        'transactionId':this.transactionId,
        "credential": {
          "demographic": {
            "name": this.dgName,
            "gender": this.dgGender,
            "dateOfBirth": this.datePipe.transform(this.dgDob, 'yyyy-MM-dd'),
            "identifier": { 
              "type": "MOBILE",
              "value": this.dgMobile
            }
          }
        }
      }
    
       this.registrationService.initDemographicMode(payLoad).subscribe(res => {
    
    
    if(res.status==='OK'){
      // if(res.data.response && res.data.response!=null){
        this.toastr.success(res.message);
      this.respData = res.data;
     
      setTimeout(() => {
         
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.confirmCallback();
    }, 5000);
      // }else{
      //   this.toastr.error(res.data.response.error.message);
      //     return;
      // }
    
    } else{
      this.toastr.error(res.message);
    }
    
    
    })
    
    } else{
      this.toastr.error('Please enter all mandatory fields')
    }


}
gettimeFormat(time: string) {
  const date_time = `${this.datePipe.transform(new Date(), 'yyyy-MM-dd')} ${time}`;
  return new Date(date_time);
}
  submitForm() {
    $('#btnsubmit').click();
  }
  close() {
    $("#appModal").modal("hide");
  }
  closeterms() {
    $("#termsModal").modal("hide");
  }

}
