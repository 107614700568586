import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './modules/login/login.component';
import { LaboratoryRegistrationComponent } from './modules/registration/laboratory-registration/laboratory-registration.component';
import { PharmacyRegistrationComponent } from './modules/registration/pharmacy-registration/pharmacy-registration.component';
import { HomeComponent } from './modules/home/home.component';
import { PlanOfCareComponent } from './modules/plan-of-care/plan-of-care.component';
import { DoctorRegistrationComponent } from './modules/registration/doctor-registration/doctor-registration.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ConsultationComponent } from './modules/consultation/consultation.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { UserprofileComponent } from './modules/userprofile/userprofile.component'
import { PreviousConsultationComponent } from './modules/modals/previous-consultation/previous-consultation.component';
import { ChiefComplaintsComponent } from './modules/modals/chief-complaints/chief-complaints.component';
import { HistoryOfPresentIllnessComponent } from './modules/modals/history-of-present-illness/history-of-present-illness.component';
import { PreviousHistoryComponent } from './modules/modals/previous-history/previous-history.component';
import { VitalsComponent } from './modules/modals/vitals/vitals.component';
import { InvestigationsComponent } from './modules/modals/investigations/investigations.component';

import { DrugsComponent } from './modules/modals/drugs/drugs.component';
import { AdviceComponent } from './modules/modals/advice/advice.component';
import { Covid19Component } from './modules/modals/covid19/covid19.component';
import { ConsultationPreviewComponent } from './modules/modals/consultation-preview/consultation-preview.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { WebcamModule } from 'ngx-webcam';

import { CompaniesListComponent } from './modules/masters/companies-list/companies-list.component';
import { CompanyComponent } from './modules/masters/company/company.component';
import { LabInvestigationComponent } from './modules/masters/lab-investigation/lab-investigation.component';
import { LabInvestigationListComponent } from './modules/masters/lab-investigation-list/lab-investigation-list.component';
import { DrugComponent } from './modules/masters/drug/drug.component';
import { DrugsListComponent } from './modules/masters/drugs-list/drugs-list.component';
import { PromotionComponent } from './modules/masters/promotion/promotion.component';
import { PromotionListComponent } from './modules/masters/promotion-list/promotion-list.component';
import { RoleComponent } from './modules/admin/role/role.component';
import { RoleListComponent } from './modules/admin/role-list/role-list.component';
import { PermissionListComponent } from './modules/admin/permission-list/permission-list.component';
import { UserDetailsComponent } from './modules/admin/user-details/user-details.component';
import { UserDetailListComponent } from './modules/admin/user-detail-list/user-detail-list.component';

import { StateComponent } from './modules/masters/state/state.component';
import { StatesListComponent } from './modules/masters/states-list/states-list.component';
import { DistrictComponent } from './modules/masters/district/district.component';
import { DistrictListComponent } from './modules/masters/district-list/district-list.component';
import { MandalComponent } from './modules/masters/mandal/mandal.component';
import { MandalListComponent } from './modules/masters/mandal-list/mandal-list.component';
import { CityComponent } from './modules/masters/city/city.component';
import { CityListComponent } from './modules/masters/city-list/city-list.component';
import { VillageComponent } from './modules/masters/village/village.component';
import { VillageListComponent } from './modules/masters/village-list/village-list.component';
import { HospitalDetailComponent } from './modules/registration/hospital-detail/hospital-detail.component';
import { HospitalDetailListComponent } from './modules/registration/hospital-detail-list/hospital-detail-list.component';
import { LabListComponent } from './modules/registration/lab-list/lab-list.component';
import { PharmacyListComponent } from './modules/registration/pharmacy-list/pharmacy-list.component';
import { PatientListComponent } from './modules/registration/patient-list/patient-list.component';
import { PatientRegistrationComponent } from './modules/registration/patient-registration/patient-registration.component';
import { DoctorListComponent } from './modules/registration/doctor-list/doctor-list.component';
import { InActiveListComponent } from './modules/registration/in-active-list/in-active-list.component';
import { AppointmentComponent } from './modules/consultation/appointment/appointment.component';
import { PaymentComponent } from './modules/consultation/payment/payment.component';
import { SupportComponent } from './modules/consultation/support/support.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CouponListComponent } from './modules/masters/coupon-list/coupon-list.component';
import { CouponDetailsComponent } from './modules/masters/coupon-details/coupon-details.component';
import { PocComponent } from './modules/poc/poc.component';
import { VerifyOTPComponent } from './modules/verify-otp/verify-otp.component';
import { ConfigurationComponent } from './modules/configuration/configuration.component';
import { UploadLogoComponent } from './modules/configuration/upload-logo/upload-logo.component';
import { CustomisePrescriptionComponent } from './modules/configuration/customise-prescription/customise-prescription.component';
import { PaymentGatewayComponent } from './modules/configuration/payment-gateway/payment-gateway.component';
import { SMSSMTPComponent } from './modules/configuration/sms-smtp/sms-smtp.component';
import { UploadConsentFormsComponent } from './modules/configuration/upload-consent-forms/upload-consent-forms.component';
import { PrescriptionComponent } from './modules/modals/prescription/prescription.component';
import { OrganizationListComponent } from './modules/configuration/organization-list/organization-list.component';
import { OrganizationComponent } from './modules/configuration/organization/organization.component';
import { NgxTinymceModule } from 'ngx-tinymce';
import { NodalOfficerListComponent } from './modules/masters/nodal-officer-list/nodal-officer-list.component'
import { NodalOfficerDetailComponent } from './modules/masters/nodal-officer-detail/nodal-officer-detail.component'

import { FavoriteComponent } from './modules/consultation/favorite/favorite.component'

import { ViewFileComponent } from './modules/modals/view-file/view-file.component';
import { ImagePreviewComponent } from './modules/modals/image-preview/image-preview.component';
import { CovidComponent } from './modules/configuration/covid/covid.component';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { DrugTypeMappingComponent } from './modules/masters/drug-type-mapping/drug-type-mapping.component';
import { DrugTypeMappingListComponent } from './modules/masters/drug-type-mapping-list/drug-type-mapping-list.component';
import { RefundRequestComponent } from './modules/admin/refund-request/refund-request.component'
import { RefundListComponent } from './modules/admin/refund-list/refund-list.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { TransactionStatusComponent } from '../app/modules/consultation/transaction-status/transaction-status.component';

import { TestComponent } from './modules/test/test.component';
import { ManualprescriptionComponent } from './modules/modals/manualprescription/manualprescription.component';
import { MissedcallComponent } from './modules/modals/missedcall/missedcall.component';
import { IvideoComponent } from './modules/modals/ivideo/ivideo.component';
import { AgentListComponent } from './modules/registration/agent-list/agent-list.component';
import { AgentControlsComponent } from './modules/registration/agent-controls/agent-controls.component';
import { ReferralListComponent } from './modules/masters/referral-list/referral-list.component';
import { ReferralControlsComponent } from './modules/masters/referral-controls/referral-controls.component';
import { IvideosComponent } from './modules/modals/ivideos/ivideos.component';
import { AgentRegistrationComponent } from './modules/registration/agent-registration/agent-registration.component';
// import { LoginOldComponent } from './modules/login-old/login-old.component';
import { OrgPlansComponent } from './modules/configuration/org-plans/org-plans.component';
import { OrgPlansControlsComponent } from './modules/configuration/org-plans-controls/org-plans-controls.component';
import { DrTemplateListComponent } from './modules/configuration/dr-template-list/dr-template-list.component';
import { DrTemplateControlsComponent } from './modules/configuration/dr-template-controls/dr-template-controls.component';
import { CorporateplanslistComponent } from './modules/configuration/corporateplanslist/corporateplanslist.component';
import { CorporateplanscontrolsComponent } from './modules/configuration/corporateplanscontrols/corporateplanscontrols.component';
import { EnrollcorpemplistComponent } from './modules/masters/enrollcorpemplist/enrollcorpemplist.component';
import { EnrollcorpempcontrolsComponent } from './modules/masters/enrollcorpempcontrols/enrollcorpempcontrols.component';
import { DrModuleControlsComponent } from './modules/configuration/dr-module-controls/dr-module-controls.component';
import { AgentlistCallComponent } from './modules/consultation/agentlist-call/agentlist-call.component';
import { AgentVideoComponent } from './modules/consultation/agent-video/agent-video.component';
import { DrTmpltCreateComponent } from './modules/configuration/dr-tmplt-create/dr-tmplt-create.component';
import { ServicesListComponent } from './modules/masters/services-list/services-list.component';
import { ServicesComponent } from './modules/masters/services/services.component';
import { DrugsToAgentmappingComponent } from './modules/masters/drugs-to-agentmapping/drugs-to-agentmapping.component';
import { DrugsToAgentmappingListComponent } from './modules/masters/drugs-to-agentmapping-list/drugs-to-agentmapping-list.component';
import { DrugsToStatemappingComponent } from './modules/masters/drugs-to-statemapping/drugs-to-statemapping.component';
import { DrugsToStatemappingListComponent } from './modules/masters/drugs-to-statemapping-list/drugs-to-statemapping-list.component';
import { ApartmentComponent } from './modules/apartment/apartment.component';
import { ApartmentStatusComponent } from './modules/apartment-status/apartment-status.component';
import { VendorRegistrationComponent } from './modules/registration/vendor-registration/vendor-registration.component';
import { VendorListComponent } from './modules/registration/vendor-list/vendor-list.component';
import { CenterRegistrationComponent } from './modules/registration/center-registration/center-registration.component';
import { CenterRegistrationFormComponent } from './modules/registration/center-registration-form/center-registration-form.component';
import { DiagnosticsReportComponent } from './modules/Reports/diagnostics-report/diagnostics-report.component';
import { OtcMedicinesReportComponent } from './modules/Reports/otc-medicines-report/otc-medicines-report.component';
import { HubCoordinatorRegistrationComponent } from './modules/registration/hub-coordinator-registration/hub-coordinator-registration.component';
import { HubCoordinatorListComponent } from './modules/registration/hub-coordinator-list/hub-coordinator-list.component';
import { WebMobileSettingsComponent } from './modules/configuration/web-mobile-settings/web-mobile-settings.component';
import { TenantFeaturesComponent } from './modules/configuration/tenant-features/tenant-features.component';
import { ReferralComponent } from './modules/Referral/referral/referral.component';
import { ReferralsListComponent } from './modules/Referral/referrals-list/referrals-list.component';
import { HubListComponent } from './modules/registration/hub-list/hub-list.component';
import { HubRegistrationComponent } from './modules/registration/hub-registration/hub-registration.component';
import { ActiveDoctorsListComponent } from './modules/consultation/active-doctors-list/active-doctors-list.component';
import { ReferalPatientListComponent } from './modules/consultation/referal-patient-list/referal-patient-list.component';
import { DssReportComponent } from './modules/Reports/dss-report/dss-report.component';
import { AttendanceReportComponent } from './modules/Reports/attendance-report/attendance-report.component';
import { FamilyMemberRegistrationComponent } from './modules/registration/family-member-registration/family-member-registration.component';
import { DrugDispenseComponent } from './modules/consultation/drug-dispense/drug-dispense.component';
import { MedicinesDetailsComponent } from './modules/consultation/medicines-details/medicines-details.component';
import { DssFlowComponent } from './modules/registration/dss-flow/dss-flow.component';
import { AssessmentManagementComponent } from './modules/masters/assessment-management/assessment-management.component';
import { AssesmentReportComponent } from './modules/Reports/assesment-report/assesment-report.component';
import { AttendanceCapturingComponent } from './modules/masters/attendance-capturing/attendance-capturing.component';
import { StockManagementComponent } from './modules/masters/stock-management/stock-management.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { EhrReportComponent } from './modules/Reports/ehr-report/ehr-report.component';
import { ExcelService } from './shared/services/excel.service';
import { PasswordStrengthBarComponent } from './modules/password-strength-bar/password-strength-bar.component';
import { DssReportNewComponent } from './modules/Reports/dss-report-new/dss-report-new.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { HandoverformReportComponent } from './modules/Reports/handoverform-report/handoverform-report.component';
import { NewPocComponent } from './modules/new-poc/new-poc.component';
 import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { NetworkStatusReportComponent } from './modules/Reports/network-status-report/network-status-report.component';
import { NetworkMonthlyReportComponent } from './modules/Reports/network-monthly-report/network-monthly-report.component';
import { ConsultationLinkWithAbhaComponent } from './modules/consultation/consultation-link-with-abha/consultation-link-with-abha.component';
import { ViewMapComponent } from './modules/view-map/view-map.component';
import { DoctorListNewComponent } from './modules/registration/doctor-list-new/doctor-list-new/doctor-list-new.component';
import { MultiDatePickerComponent } from './modules/registration/multiselect-date/multi-date-picker/multi-date-picker.component';
import { DoctorSlotModalComponent } from './modules/registration/doctor-slot-modal/doctor-slot-modal.component';
import { NdhmNotificationComponent } from './modules/registration/ndhm-notification/ndhm-notification.component';
import { ConsentListComponent } from './modules/ndhm/consent-list/consent-list.component';
import { ConsentRegistrationComponent } from './modules/ndhm/consent-registration/consent-registration.component';
import { ConsentViewFilesComponent } from './modules/ndhm/consent-view-files/consent-view-files.component';
import { ConsentFileDetailsComponent } from './modules/ndhm/consent-file-details/consent-file-details.component';
import { PatientEmrComponent } from './modules/consultation/patient-emr/patient-emr.component';
@NgModule({
  declarations: [
    AppComponent, AppointmentComponent, PaymentComponent, SupportComponent,
    MultiDatePickerComponent,
    RefundListComponent,
    LoginComponent, LaboratoryRegistrationComponent, PharmacyRegistrationComponent,
    ModalContentComponent, HomeComponent, PlanOfCareComponent, DoctorRegistrationComponent, ForgotPasswordComponent, ConsultationComponent, ChangePasswordComponent, ViewFileComponent, PreviousConsultationComponent, ChiefComplaintsComponent, HistoryOfPresentIllnessComponent, PreviousHistoryComponent, VitalsComponent, InvestigationsComponent, ImagePreviewComponent, DrugsComponent, AdviceComponent, Covid19Component, ConsultationPreviewComponent, CompaniesListComponent, CompanyComponent, LabInvestigationComponent, LabInvestigationListComponent, DrugComponent, DrugsListComponent, PromotionComponent, PromotionListComponent, RoleComponent, RoleListComponent, PermissionListComponent, UserDetailsComponent, UserDetailListComponent, HospitalDetailComponent, HospitalDetailListComponent, LabListComponent, PharmacyListComponent, DoctorListComponent, InActiveListComponent, PatientListComponent, PatientRegistrationComponent, CouponListComponent, CouponDetailsComponent, PocComponent, UserprofileComponent, VerifyOTPComponent, ConfigurationComponent, UploadLogoComponent, CustomisePrescriptionComponent, PaymentGatewayComponent, SMSSMTPComponent, UploadConsentFormsComponent, OrganizationListComponent, PrescriptionComponent,
    StateComponent, StatesListComponent, DistrictComponent, DistrictListComponent, OrganizationComponent, CovidComponent,
MandalComponent, MandalListComponent, CityComponent, CityListComponent, VillageComponent, VillageListComponent, NodalOfficerListComponent, NodalOfficerDetailComponent, FavoriteComponent, DrugTypeMappingComponent, DrugTypeMappingListComponent, RefundRequestComponent,DashboardComponent, TransactionStatusComponent, TestComponent, ManualprescriptionComponent, MissedcallComponent, IvideoComponent, AgentListComponent, AgentControlsComponent, ReferralListComponent, ReferralControlsComponent, IvideosComponent, AgentRegistrationComponent, 
// LoginOldComponent, 
OrgPlansComponent, OrgPlansControlsComponent, DrTemplateListComponent, DrTemplateControlsComponent, CorporateplanslistComponent, CorporateplanscontrolsComponent, EnrollcorpemplistComponent, EnrollcorpempcontrolsComponent, DrModuleControlsComponent, AgentlistCallComponent, AgentVideoComponent, DrTmpltCreateComponent, ServicesListComponent, ServicesComponent, DrugsToAgentmappingComponent, DrugsToAgentmappingListComponent, DrugsToStatemappingComponent, DrugsToStatemappingListComponent, ApartmentComponent, ApartmentStatusComponent, VendorRegistrationComponent, VendorListComponent, CenterRegistrationComponent, CenterRegistrationFormComponent, DiagnosticsReportComponent, OtcMedicinesReportComponent, HubCoordinatorRegistrationComponent, HubCoordinatorListComponent, WebMobileSettingsComponent, TenantFeaturesComponent, ActiveDoctorsListComponent,ReferralComponent, ReferralsListComponent, HubListComponent, HubRegistrationComponent, ReferalPatientListComponent, DssReportComponent, AttendanceReportComponent, FamilyMemberRegistrationComponent, DrugDispenseComponent, MedicinesDetailsComponent,DssFlowComponent, AssessmentManagementComponent,AttendanceCapturingComponent, StockManagementComponent
,AssesmentReportComponent,SafePipe, EhrReportComponent, HandoverformReportComponent, NewPocComponent, NetworkStatusReportComponent, NetworkMonthlyReportComponent, ConsultationLinkWithAbhaComponent, ViewMapComponent, DoctorListNewComponent, MultiDatePickerComponent, DoctorSlotModalComponent, NdhmNotificationComponent, ConsentListComponent, ConsentRegistrationComponent, ConsentViewFilesComponent, ConsentFileDetailsComponent, PatientEmrComponent],
  imports: [
    HttpClientModule,
    SharedModule,
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgxAudioPlayerModule,
    NgxTinymceModule.forRoot({
      // baseURL: './assets/tinymce/',
      // or cdn
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/'
    }),
    BrowserAnimationsModule, // required animations module
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    ReactiveFormsModule, FormsModule,
    TimepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({ preventDuplicates: true }), // ToastrModule added
    WebcamModule,
  ],
  providers: [NgbActiveModal,ExcelService,DatePipe],
  // providers: [NgbActiveModal, { provide: LocationStrategy, useClass: HashLocationStrategy },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }