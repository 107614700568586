import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// const httpOptions = { 
//     headers: new HttpHeaders({ 'Content-Type': 'application/json',
//     //sushma 02-08-2022 cors error resolve
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods":"DELETE, POST, GET, OPTIONS",
//     "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
//     },
//     ) 
// };

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

// const httpOptionsR = {

//     headers: new HttpHeaders({
//         // 'Content-Type': 'application/json',
//         // 'Accept': 'application/json',
//         // 'Access-Control-Allow-Origin': 'http://localhost:4200'

//         'Content-Type': 'application/json',
//                   'Access-Control-Allow-Origin': '*',
//                   'Access-Control-Allow-Credentials': 'true',
//                   'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS',
//                   'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept, X-Custom-Header, Upgrade-Insecure-Requests',
//     })
// };

@Injectable({ providedIn: 'root' })

export class ApiService {

    constructor(private http: HttpClient) { }

    loginAPI = ''; tntAPI = ''; appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/';

    get(url: string) {
        return this.http.get(url, httpOptions);
    }

    // get(url: string) {
    //     return this.http.get(url, httpOptionsR);
    // }

    // get(url: string) {
    //     return this.http.get(`${this.loginAPI}${url}`);
    // }

    post(url: string, postData: Object) {
        return this.http.post(url, postData);
    }

    post2(url: string, postData: Object) {
        return this.http.post(url, postData, httpOptions);
    }

    post3(url: string, postData: Object, httpOptns: any) {
        return this.http.post(url, postData, httpOptns);
    }

    put(url: string, postData: Object) {
        return this.http.put(url, postData);
    }

    patch(url: string, postData: Object) {
        return this.http.patch(url, postData);
    }

    delete(url: string) {
        return this.http.delete(url);
    }

}